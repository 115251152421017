.auto-apply-form-wrapper {
  .form-logo {
    margin-bottom: 10px;
  }

  .section-text {
    color: var(--secondary-text-color);
    margin-bottom: 25px;
  }

  .form-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    .multiple-colon-form-item {
      padding: 10px 0px;

      .button-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .switch-with-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
      }

      .ant-form-item {
        width: 50%;

        label {
          font-size: 15px;
          font-weight: 500;
        }
      }

      .form-item-with-desc {
        width: 50%;

        .ant-form-item {
          width: 100%;
          margin-bottom: 0;

        }

        .form-item-desc {
          color: var(--secondary-text-color);
        }
      }
    }

  }

}