.review-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .review-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;

    &:last-of-type {
      border: none;
    }
  }

  .review-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .review-card-buttons {
      display: flex;
      align-items: center;
      gap: 15px;

      button {
        padding: 0px;
        color: var(--text-color);
      }
    }
  }

  h4,
  p {
    margin: 0px !important;
  }
}