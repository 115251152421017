.register-wizard-form-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2.5rem;

  .register-wizard-form-section {
    width: 500px;

    .register-wizard-form-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .skip-btn {
        font-weight: 700;
        font-size: 20px;
        color: var(--primary-color);
      }

      .back-btn {
        color: #000;
        font-size: 20px;
      }

      button {
        padding: 0 !important;
      }
    }

    .register-wizard-form-inner {
      padding: 0px 40px;

      .form-progress-bar {
        padding-bottom: 20px;

        .ant-progress-bg {
          height: 4px !important;
          background-color: var(--primary-color);
        }
      }
    }

  }
}