.auto-apply-page-wrapper {
  .promo-cards {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .auto-apply-cards {
      width: 58%;

      .large-card {
        position: relative;
        background: linear-gradient(180deg, #7671d5 0%, #594779 100%);
        width: 100%;
        margin-bottom: 40px;
        overflow: hidden;

        .card-section {
          padding: 40px 25px;
          box-sizing: border-box;
          width: 50%;
          height: 100%;
          position: absolute;
          right: 0px;
          top: 0px;
          background: linear-gradient(270deg, #655a9f 0%, #5f518e 57.22%, rgba(89, 71, 121, 0) 100%);
          display: flex;
          flex-direction: column;
          align-items: end;
          justify-content: space-between;

          .slogan {
            font-size: 40px;
            font-weight: 700;
            color: white;
            text-align: end;
          }

          button {
            min-width: 200px;
            max-width: 50%;
          }
        }
      }

      .auto-apply-card-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 25px;
      }
    }

    .activity-graphs {
      width: 39%;
      min-width: 300px;

      .section-title {
        margin-bottom: 20px;
      }

      .doughnut-chart-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 40px;

        .doughnut-chart {
          height: 300px;
        }
      }

      .line-chart-wrapper {
        margin: 40px 0px;
      }

      .interview-reminder {
        .section-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          .section-title {
            margin-bottom: 0px;
          }
        }

        .swiper-container {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .auto-apply-cards {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .activity-graphs {
    width: 100% !important;
  }
}
