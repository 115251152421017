.form-wrapper {
  position: relative;

  .form-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .form-title {
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      color: var(--secondary-text-color);
    }

    .form-desc {
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .extra-info-text {
    font-size: 12px;
    font-weight: 400;
    color: #aaa;
  }

  .relevant-link-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .relevant-link-item {
      display: flex;
      align-items: center;
      gap: 20px;

      .relevant-link-item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0px;
      }

      .ant-form-item {
        margin: 0px;
        width: 100%;
      }
    }
  }
}

.review-card-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px;
  width: 100%;
}

.form-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.types-of-employment {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .ant-tag-checkable {
    background: #eee;
  }

  .ant-tag-checkable-checked {
    background: var(--primary-color);
  }
}