.edit-profile-wrapper {
    .app-btn-primary-outline {
        font-size: 18px;
        border-radius: 10px;
        height: unset;
        width: 170px;
        color: var(--primary-color);
    }

    .app-btn-primary {
        // max-width: 170px;
        min-width: 170px;
        width: auto;
        height: unset;
        font-size: 18px;
        border-radius: 10px;
    }

    .title {
        font-size: 24px;
        color: var(--text-header-color);
        font-weight: 600;
    }

    .container {
        padding: 22px;
        border-radius: 10px;
        background: white;
    }
    
    .space-horizontal {
        margin: 0px 18px;
    }
    
    .space-vertical {
        margin: 18px 0px;
    }

    .resume-section {
        border: 1px solid var(--primary-color);

        .pdf-box {
            background-color: #f8e8ff;
            margin-top: 32px;
            padding: 18px;
            border-radius: 10px;
            .pdf-icon {
                background-color: #f0cdff;
                padding: 8px;
                border-radius: 10px;
                border: 1px solid #dbdbdb;
            }

            .title {
                font-size: 16px;
                font-weight: 700;
            }

            .subtitle {
                font-size: 14px;
                color: #9b9b9b;
            }
        }
    }

    .personal-information-section {
        .pi-grid {
            margin-top: 24px;

            .left-section {
                margin-right: 2vw;
                min-width: 18vw;
            }

            .right-section {
                margin-left: 2vw;
                width: 100%;
                max-width: 40vw;
            }
        }

        .multiple-colon-form-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 25px;
        }

        .social-link-container {
            margin: 18px 0px;
            .social-icon {
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                padding: 10px;
                .anticon {
                    font-size: 18px;
                }
            }
            .social-input {
                margin-left: 18px;
            }
        }
    }
    
    .review-card-buttons {
        display: flex;
        align-items: center;
        gap: 15px;
  
        button {
          padding: 0px;
          color: var(--primary-color)!important;
        }
      }
}
