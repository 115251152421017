section,
.section {
  border: 1px solid #edf2f0;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  color: var(--text-color);
  width: 100%;
  box-sizing: border-box;
}

.section-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: var(--text-color);
}

.section-subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: var(--text-color);
}

.section-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--primary-text-color);
}

.form-item-with-info {
  margin-bottom: 24px;

  .ant-form-item {
    margin-bottom: 0px !important;
    width: 100%;
  }
}

a {
  color: var(--link-color) !important;
  cursor: pointer;
}

.error-message {
  color: var(--danger-color);
  font-size: 14px;
}

.multiple-colon-form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

.multiple-colon-form-item-item-label {
  margin-bottom: 10px;
}

.app-btn-primary {
  width: 100%;
  height: 45px;
  font-size: 20px;
  background-color: var(--primary-color);
}

.app-btn-primary {
  width: 100%;
  height: 45px;
  font-size: 20px;
  background-color: var(--primary-color);
}

.app-btn-primary-outline {
  width: 100%;
  height: 45px;
  font-size: 20px;
  border: 2px solid var(--primary-color);
}

.cursor-pointer {
  cursor: pointer;
}

.third-party-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--secondary-text-color);

  .btn-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.ant-form-item {
  width: 100%;

  .ant-picker {
    width: 100%;
  }
}