.promo-card {
  border: none;
  color: white;
  text-align: end;
  border-radius: 8px;

  .promo-card-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
  }

  .promo-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .promo-card-icon {
    width: 50px;
    height: 50px;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff3b;
    border-radius: 8px;
    padding: 10px;
  }

  .promo-card-texts {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    gap: 10px;

    .section-title {
      color: white;
      font-size: 34px;
    }

    .section-text {
      font-size: 12px;
    }
  }
}