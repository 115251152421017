.profile-card {
    .ant-card-body {
        padding: 16px;
        width: 300px;
        height: 367px;
        max-height: auto;
        position: relative;
    }

    .ant-tag.ant-tag-success {
        color: #177F15;
        background-color: #98E3C8;
    }

    .profile-picture {
        margin: 24px;
        display: flex;
        justify-content: center;
    }

    .title,
    .subtitle {
        text-align: center;
        margin: 6px;
    }

    .title {
        font-size: 24px;
    }

    .subtitle {
        font-size: 14px;
        color: #AFAFAF;
    }

    .action {
        margin-top: 24px;

        .app-btn-primary-outline {
            width: 100%;
            border-radius: 10px;
            color: var(--primary-color);
        }
    }

    .add-new-profile {
        height: 100%;
        cursor: pointer;

        .add-icon {
            font-size: 85px;
            color: var(--primary-color);
        }
    }
}