.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  .login-inner {
    width: 500px;
    padding: 40px 0px;

    .third-party-login-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 40px 60px !important;
      box-sizing: border-box;

      .login-btn-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .login-section {
      display: flex;
      flex-direction: column;
      padding: 40px 60px !important;
      gap: 20px;

      .login-titles {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
      }

      form {
        width: 100% !important;
      }

      .login-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        .ant-form-item {
          margin: 0px !important;
        }
      }
    }
  }
}