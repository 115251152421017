.auto-apply-card {
  min-width: 300px;
  width: 100%;

  .section-header {
    position: relative;
    top: -40px;
    margin-bottom: -40px;
    display: flex;
    align-items: flex-end;
    gap: 20px;

    .logo {
      width: 18%;

      img {
        border-radius: 20px;
      }
    }

    .logo-expanded {
      width: 15%;
      padding-bottom: 10px;
    }
  }

  .bullet-list {
    color: var(--secondary-text-color);

    ul {
      padding-left: 20px;
    }
  }

  .card-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

}