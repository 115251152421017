.onboarding-review-wrapper {

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
  }

  .icon-text {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .section-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  .error-message {
    margin-bottom: 10px;
  }
}