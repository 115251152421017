.upload-cv-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-cv-section {
    width: 500px !important;
    margin-top: 20px;
    padding: 0px;

    .section-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f7f7f7;
    }

    .section-body {
      padding: 40px;

      .section-text {
        font-size: 12px;
        margin-bottom: 40px;
      }

      .upload-component {
        height: 150px;
        padding: 20px 0px;
      }

      .section-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        Button {
          width: 200px;
        }

        .skip-btn {
          Button {
            color: var(--primary-color);
          }
        }

        .btn-layout-top {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }
      }
    }
  }
}