.reminder-card {
  border: none;
  margin-top: 10px;

  .section-left {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .title > * {
    margin-bottom: 0px !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: var(--primary-color) !important;
  }

  .title .anchor:hover {
    text-decoration: underline;
  }

  .section-text {
    text-align: right;
  }
}
